<template>
  <v-card class=" px-4">
    <div class="head-modal pt-5 pb-6">
      <div
        class="d-flex justify-center"
        style="width: 100%;"
      >
        <span>
          Visualização do veículo
        </span>
      </div>

      <v-icon
        medium
        class="me-2"
        @click="closeModal()"
      >
        {{ icons.mdiClose }}
      </v-icon>
    </div>
    <div class="px-3">
      <Separator
        label="Dados do veículo"
      />
      <v-text-field
        v-model="vehicleData.chassi"
        class="mt-8"
        readonly
        outlined
        dense
        label="Chassi"
      />
      <v-text-field
        v-model="vehicleData.modelo"
        readonly
        outlined
        dense
        label="Modelo"
      />
      <div
        class="d-flex"
        style="gap: 20px; justify-content: start; align-items: start;"
      >
        <v-text-field
          v-model="vehicleData.combustivel"
          readonly
          outlined
          dense
          label="Combustível"
        />
        <v-text-field
          v-model="vehicleData.quilometragem"
          readonly
          outlined
          dense
          label="Quilometragem"
        />
      </div>
      <div
        class="d-flex"
        style="gap: 20px;"
      >
        <v-text-field
          v-model="vehicleData.cor"
          readonly
          outlined
          dense
          label="Cor"
        />
        <v-text-field
          v-model="vehicleData.anomodelo"
          readonly
          outlined
          dense
          label="Ano"
        />
      </div>
      <Separator
        label="Imagem do veículo"
      />
      <div
        class="py-8"
      >
        <label
          for="ImageInput"
          class="file-label"
        >
          <div
            v-if="!previewURL && !((vehicleData.has_image !== undefined))"
            class="upload-placeholder"
          >
            <span class="upload-text">Adicionar imagem</span>
            <span class="upload-text">+</span>
          </div>
          <v-img
            v-else
            class="image-preview"
            alt="Imagem de preview"
            :src="imageURL"
          ></v-img>
          <v-btn
            icon
            color="error"
            style="position: absolute; z-index: 10; top: 5px; right: 5px;"
            @click="handleEraseImage"
          >
            <v-icon color="error">{{ icons.mdiTrashCanOutline }}</v-icon>
          </v-btn>
          <input
            id="ImageInput"
            type="file"
            accept="image/jpg, image/jpeg"
            class="hidden-input"
            @change="handleFileChange"
          />
        </label>
      </div>
      <div
        class="d-flex pb-4"
        style="width: 100%; justify-content: space-between; align-items: center;"
      >
        <v-btn
          color="error"
          :disabled="isLoading"
          @click="closeModal()"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="info"
          :disabled="isLoading || !previewURL"
          @click="updateImage"
        >
          <span v-if="!isLoading ">Atualizar</span>
          <v-progress-circular
            v-else
            color="success"
            indeterminate
          >
          </v-progress-circular>
        </v-btn>
      </div>
    </div>
  </v-card>
</template>

<script>
import Separator from '@/components/Separator.vue'
import axiosFileApi from '@/plugins/axiosFileApi'
import { mdiClose, mdiTrashCanOutline } from '@mdi/js'

export default {
  name: 'ModalEditVehicle',
  components: { Separator },
  props: {
    vehicleData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      icons: {
        mdiClose, mdiTrashCanOutline,
      },
      imageURL: null,
      previewURL: null,
      file: null,
      isLoading: false,
      hasImage: false,
    }
  },
  created() {
    if (this.vehicleData.has_image) {
      this.getImageUrl()
    }
  },
  methods: {
    handleEraseImage() {
      this.imageURL = ''
      this.previewURL = ''
      this.vehicleData.has_image = undefined
    },
    async getImageUrl() {
      try {
        const { data } = await axiosFileApi.get(`/api/v1/utilities/images_path_autoforce_api/get_route_by_chassi/${this.vehicleData.chassi}`)

        this.imageURL = data
      } catch (error) {
        console.error('Ocorreu um erro ao buscar a imagem:', error)
        this.$swal({
          icon: 'error',
          title: 'Erro!',
          text: 'Ocorreu um erro ao buscar a imagem!',
          showConfirmButton: false,
          timer: 3000,
        })
      }
    },
    async updateImage() {
      this.isLoading = true

      if (!this.file) {
        this.$swal({
          icon: 'warning',
          title: 'Atenção!',
          text: 'Você deve inserir uma imagem!',
          showConfirmButton: false,
          timer: 3000,
        })
        this.isLoading = false

        return
      }
      if (this.file.type !== 'image/jpeg' && this.file.type !== 'image/jpg') {
        this.$swal({
          icon: 'warning',
          title: 'Atenção!',
          text: 'O tipo da imagem deve ser jpg/jpeg!',
          showConfirmButton: false,
          timer: 3000,
        })
        this.isLoading = false

        return
      }
      try {
        const formData = new FormData()
        formData.append('chassi', this.vehicleData.chassi)
        formData.append('image', this.file)
        await axiosFileApi.post('/api/v1/utilities/api_for_auto_force_consumption/upload_image', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        this.$swal({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Imagem alterada com sucesso!',
          showConfirmButton: false,
          timer: 3000,
        })
        this.$emit('updatedTable')
      } catch (error) {
        console.error('Ocorreu um erro ao atualizar imagem:', error)
        this.$swal({
          icon: 'error',
          title: 'Erro!',
          text: 'Ocorreu um erro ao alterar a imagem!',
          showConfirmButton: false,
          timer: 3000,
        })
      } finally {
        this.isLoading = false
      }
    },
    handleFileChange(event) {
      const fileSelected = event.target.files[0]
      this.file = fileSelected
      if (this.file) {
        this.imageURL = URL.createObjectURL(this.file)
        this.previewURL = URL.createObjectURL(this.file)
      }
    },
    closeModal() {
      this.$emit('close')
    },
  },
}
</script>

<style>
.hidden-input {
  display: none;
}
.head-modal{
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.file-label {
  width: 100%;
  height: 400px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.2s ease-in-out;
  border: 1px white dashed;
  border-radius: 5px;
  position: relative;
}
.file-label:hover {
  opacity: 0.8;
}
.upload-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  background: #e7e3fc0a;
  border-radius: 5px;

}
.upload-text {
  color: #ffffff;
  font-size: 0.9rem;
  font-weight: 500;
  text-align: center;
}
.image-preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}
</style>
