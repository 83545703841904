<template>
  <v-card>
    <div
      class="d-flex align-start justify-space-between px-5 mb-8 pt-8"
      style=" width: 100%;"
    >
      <h3
        class="pl-0"
        style="font-weight: 600;"
      >
        Catálogo de veículos - MOTOCA
      </h3>
      <v-text-field
        v-model="filterInput"
        style="max-width: 400px;"
        outlined
        dense
        placeholder="Pesquisar..."
        :prepend-inner-icon="icons.mdiMagnify"
        @input="handleFilterData"
      />
      <!-- <div
        class="d-flex"
        style="gap: 20px;"
      >
        <v-btn
          class=""
          outlined
          :color="filterButtonColor"
          @click="handleShowModalFilterVehicles"
        >
          <v-icon
            size="22"
            class="me-1"
          >
            {{ icons.mdiFilterVariant }}
          </v-icon>
          <span class="button-text-actions">FILTRAR</span>
        </v-btn>
        <v-btn
          v-if="isFiltered"
          outlined
          color="error"
          @click="clearFilters"
        >
          <v-icon
            size="22"
            class="me-1"
          >
            {{ icons.mdiFilterVariant }}
          </v-icon>
          <span class="button-text-actions">LIMPAR</span>
        </v-btn>
      </div> -->
    </div>
    <v-data-table
      :headers="headers"
      :items="listOfFilteredItems"
      :loading="loadingTable"
      loading-text="Carregando dados..."
      class="text-no-wrap"
    >
      <template v-slot:item.chassi="{ item }">
        <div
          class="d-flex"
          style="align-items: center; gap: 5px;"
        >
          <v-tooltip
            v-if="item.has_image"
            color="success"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                color="success"
                size="20"
                v-on="on"
              >
                {{ icons.mdiCheckDecagramOutline }}
              </v-icon>
            </template>
            <span>Imagem já adicionada!</span>
          </v-tooltip>
          <span>{{ item.chassi }}</span>
        </div>
      </template>
      <template v-slot:item.editar="{ item }">
        <v-btn
          icon
          color="info"
          @click="handleOpenEditModal(item)"
        >
          <v-icon color="info">
            {{ icons.mdiPencil }}
          </v-icon>
        </v-btn>
      </template>
      <template
        v-slot:no-data
      >
        <div class="my-10">
          <NoDataInterfaceEditable
            title="Nenhum  registro encontrado!"
            subtitle="Por favor, verifique os filtros de pesquisa / Contacte a equipe de desenvolvimento."
          />
        </div>
      </template>
    </v-data-table>
    <v-dialog
      v-model="showModalEditVehicle"
      width="700px"
    >
      <ModalEditVehicle
        :key="keyModalEditVehicle"
        :vehicle-data="vehicleData"
        @close="handleCloseModal()"
        @updatedTable="getTableData()"
      ></ModalEditVehicle>
    </v-dialog>
    <!-- <v-dialog
      v-model="showModalFilterVehicles"
      width="700px"
      transition="dialog-transition"
    >
      <ModalFilterVehicles
        ref="showModalFilterVehiclesRef"
        @close="showModalFilterVehicles = false"
        @filterData="handleFilterData"
      />
    </v-dialog> -->
  </v-card>
</template>

<script>
import { getVuetify } from '@/@core/utils'
import NoDataInterfaceEditable from '@/components/charts/NoDataInterfaceEditable.vue'
// eslint-disable-next-line import/no-cycle
import axiosFileApi from '@/plugins/axiosFileApi'
import {
  mdiCheckDecagramOutline,
  mdiClose,
  mdiExclamationThick,
  mdiFilterVariant,
  mdiMagnify,
  mdiPencil,
  mdiPlaylistPlus,
} from '@mdi/js'
import localStorageSlim from 'localstorage-slim'
import ModalEditVehicle from './ModalEditVehicle.vue'

export default {
  components: {
    NoDataInterfaceEditable, ModalEditVehicle,
  },
  data() {
    return {
      icons: {
        mdiClose, mdiPlaylistPlus, mdiMagnify, mdiPencil, mdiFilterVariant, mdiExclamationThick, mdiCheckDecagramOutline,
      },
      loadingTable: false,
      listOfFilteredItems: [],
      itemsTable: [],
      headers: [
        {
          text: 'CHASSI',
          value: 'chassi',
          sortable: false,
          align: 'left',
        },
        {
          text: 'MODELO',
          value: 'modelo',
          sortable: false,
          align: 'left',
        },
        {
          text: 'ANO',
          value: 'anomodelo',
          sortable: false,
          align: 'left',
        },
        {
          text: 'COMBUSTIVEL',
          value: 'combustivel',
          sortable: false,
          align: 'left',
        },
        {
          text: 'QUILOMETRAGEM',
          value: 'quilometragem',
          sortable: false,
          align: 'left',
        },
        {
          text: 'COR',
          value: 'cor',
          sortable: false,
          align: 'left',
        },
        {
          text: 'EDITAR',
          value: 'editar',
          sortable: false,
          align: 'center',
        },
      ],
      filterInput: '',

      keyModalEditVehicle: '123',

      userProfile: {},

      showModalEditVehicle: false,
      showModalFilterVehicles: false,
      isFiltered: false,
      vehicleData: {},
    }
  },
  computed: {
    filterButtonColor() {
      if (!this.isDark) {
        return this.isFiltered ? 'success' : 'black'
      }

      return this.isFiltered ? 'success' : 'white'
    },
    isDark() {
      const $vuetify = getVuetify()

      return $vuetify.theme.dark
    },
  },
  created() {
    this.getTableData()
    this.userProfile = localStorageSlim.get('userProfile', { decrypt: true })
  },
  methods: {
    handleFilterData() {
      if (!this.filterInput) {
        this.listOfFilteredItems = this.itemsTable

        return
      }

      const searchText = this.filterInput.toLowerCase()

      this.listOfFilteredItems = this.itemsTable.filter(item => Object.values(item).some(value => String(value).toLowerCase().includes(searchText)))
    },
    handleShowModalFilterVehicles() {
      this.showModalFilterVehicles = true
    },
    handleOpenEditModal(vehicleData) {
      this.generateNewModalEditVehicleKey()
      this.vehicleData = vehicleData
      this.showModalEditVehicle = true
    },
    generateNewModalEditVehicleKey() {
      this.keyModalEditVehicle = Math.random()
    },
    handleCloseModal() {
      this.showModalEditVehicle = false
    },
    clearFilters() {
      this.$refs.modalqfitlra.handleClearFilter()
    },

    async getTableData() {
      this.loadingTable = true
      try {
        const { data } = await axiosFileApi.post('/api/v1/utilities/api_for_auto_force_consumption/return_stock_with_image_info', { condition: 2 })
        this.itemsTable = data
        this.listOfFilteredItems = this.itemsTable
        console.log()
      } catch (error) {
        console.error('erro ao buscar os dados da tabela de solicitação de marketing:', error)
      } finally {
        this.loadingTable = false
      }
    },
  },
}
</script>

<style>
</style>
