import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c('div',{staticClass:"d-flex align-start justify-space-between px-5 mb-8 pt-8",staticStyle:{"width":"100%"}},[_c('h3',{staticClass:"pl-0",staticStyle:{"font-weight":"600"}},[_vm._v(" Catálogo de veículos - MOTOCA ")]),_c(VTextField,{staticStyle:{"max-width":"400px"},attrs:{"outlined":"","dense":"","placeholder":"Pesquisar...","prepend-inner-icon":_vm.icons.mdiMagnify},on:{"input":_vm.handleFilterData},model:{value:(_vm.filterInput),callback:function ($$v) {_vm.filterInput=$$v},expression:"filterInput"}})],1),_c(VDataTable,{staticClass:"text-no-wrap",attrs:{"headers":_vm.headers,"items":_vm.listOfFilteredItems,"loading":_vm.loadingTable,"loading-text":"Carregando dados..."},scopedSlots:_vm._u([{key:"item.chassi",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex",staticStyle:{"align-items":"center","gap":"5px"}},[(item.has_image)?_c(VTooltip,{attrs:{"color":"success","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"success","size":"20"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiCheckDecagramOutline)+" ")])]}}],null,true)},[_c('span',[_vm._v("Imagem já adicionada!")])]):_vm._e(),_c('span',[_vm._v(_vm._s(item.chassi))])],1)]}},{key:"item.editar",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{attrs:{"icon":"","color":"info"},on:{"click":function($event){return _vm.handleOpenEditModal(item)}}},[_c(VIcon,{attrs:{"color":"info"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")])],1)]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"my-10"},[_c('NoDataInterfaceEditable',{attrs:{"title":"Nenhum  registro encontrado!","subtitle":"Por favor, verifique os filtros de pesquisa / Contacte a equipe de desenvolvimento."}})],1)]},proxy:true}])}),_c(VDialog,{attrs:{"width":"700px"},model:{value:(_vm.showModalEditVehicle),callback:function ($$v) {_vm.showModalEditVehicle=$$v},expression:"showModalEditVehicle"}},[_c('ModalEditVehicle',{key:_vm.keyModalEditVehicle,attrs:{"vehicle-data":_vm.vehicleData},on:{"close":function($event){return _vm.handleCloseModal()},"updatedTable":function($event){return _vm.getTableData()}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }